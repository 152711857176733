:host {
  display: block;
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  overflow:hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  > * {
    z-index: 1;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: wait;
    background: var(--sc-block-ui-background-color, var(--sc-color-white));
    opacity: var(--sc-block-ui-opacity, 0.15);
  }

  &.transparent:after {
    background: transparent;
  }
}

.overlay__content {
  font-size: var(--sc-font-size-large);
  font-weight: var(--sc-font-weight-semibold);
  display: grid;
  gap: 0.5em;
  text-align: center;
}
