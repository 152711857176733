:host {
  --width: 31rem;
  --header-spacing: var(--sc-spacing-large);
  --body-spacing: var(--sc-spacing-large);
  --footer-spacing: var(--sc-spacing-large);
  display: contents;
}
[hidden] {
  display: none !important;
}
.dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--sc-z-index-dialog);
  box-sizing: border-box;
  text-align: left;
}
.dialog__panel {
  display: flex;
  flex-direction: column;
  z-index: 2;
  width: var(--width);
  max-width: 100vw;
  max-height: 100vh;
  background-color: var(--sc-panel-background-color);
  border-radius: var(--sc-border-radius-medium);
  box-shadow: var(--sc-shadow-x-large);
  position: relative;
}
.dialog__panel:focus {
  outline: none;
}
/* Ensure there's enough vertical padding for phones that don't update vh when chrome appears (e.g. iPhone) */
@media screen and (max-width: 420px) {
  .dialog__panel {
    max-height: 80vh;
  }
}
.dialog--open .dialog__panel {
  display: flex;
  opacity: 1;
  transform: none;
}
.dialog__header {
  flex: 0 0 auto;
  display: flex;
  border-bottom: 1px solid var(--sc-color-gray-300);
}
.dialog__title {
  flex: 1 1 auto;
  font: inherit;
  font-size: var(--sc-font-size-large);
  line-height: var(--sc-line-height-dense);
  padding: var(--header-spacing);
  margin: 0;
}
.dialog__close {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  font-size: var(--sc-font-size-x-large);
  padding: 0 calc(var(--header-spacing) / 2);
  z-index:2;
}
.dialog__body {
  flex: 1 1 auto;
  padding: var(--body-spacing);
  overflow: var(--dialog-body-overflow, auto);
  -webkit-overflow-scrolling: touch;
}
.dialog__footer {
  flex: 0 0 auto;
  text-align: right;
  padding: var(--footer-spacing);
}
.dialog__footer ::slotted(sl-button:not(:first-of-type)) {
  margin-left: var(--sc-spacing-x-small);
}
.dialog:not(.dialog--has-footer) .dialog__footer {
  display: none;
}
.dialog__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--sc-overlay-background-color);
}
